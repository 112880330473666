<template>
  <b-card>
    <b-overlay
      :show="loading"
      variant="light"
      opacity="0.30"
      blur="10px"
      rounded="sm"
      class="mt-2 mb-2"
    >
      <b-row class="justify-content-md-end">
        <!-- submit and reset -->
        <b-col cols="3">
          <import-excel :on-success="loadDataInTable" />
        </b-col>
        <b-col>
          <b-button
            :href="defaultStateExcelTemplate"
            class="mr-1 float-right custom-button-color"
            type="submit"
          >
            <feather-icon
              icon="DownloadIcon"
              class="mr-50"
            />
            Download Excel Template
          </b-button>
        </b-col>
      </b-row>

      <ValidationObserver
        ref="createExcelBulkUploadForm"
        v-slot="{ handleSubmit }"
        slim
      >
        <b-form
          v-if="tableData.length > 0"
          ref="form"
          class="repeater-form"
          autocomplete="off"
          @submit.prevent="handleSubmit(onClickSubmit)"
        >

          <b-row class="mt-3 mb-2">
            <b-col
              cols="6"
              md="12"
              class="d-md-flex justify-content-center ml-1 ml-md-0"
            >
              <b-form-group
                class="required"
                label="Country"
                label-for="h-c-state"
              >
                <ValidationProvider
                  #default="{ errors }"
                  name="h-c-state"
                  vid="h-c-state"
                  rules="required"
                >
                  <v-select
                    v-model="selectedCountry"
                    :options="countryOptions"
                    :reduce="option => option.id"
                    label="name"
                    placeholder="Select Country"
                    style="width: 250px"
                    @search="(search, loading) => {
                      loading(true)
                      readDataforCountry(search).then(() => loading(false))
                    }"
                  >
                    <template #search="{attributes, events}">
                      <input
                        class="vs__search"
                        style="pointer-events:none"
                        :required="!selectedCountry"
                        v-bind="attributes"
                        v-on="events"
                      >
                    </template>
                  </v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </b-form-group>
            </b-col>
          </b-row>

          <b-table
            responsive
            :fields="fields"
            :items="tableData"
            :small="true"
            class="pt-1 excelUploadtable"
            show-empty
          >
            <template #empty>
              <div
                class="text-center p-1"
              >
                No records found to display
              </div>
            </template>
            <template #cell(state_name)="data">
              <b-form-input
                v-model="data.item.state_name"
                type="text"
                style="width: 250px; margin-left: auto; margin-right: auto;"
                required
              />
            </template>
          </b-table>

          <b-row class="justify-content-md-end">
            <!-- submit and reset -->
            <b-col>
              <b-button
                class="mr-1 float-right custom-button-color"
                type="submit"
                :disabled="loading"
              >
                Submit
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </ValidationObserver>
    </b-overlay>
  </b-card>
</template>

<script>
import {
  BCard, BTable, BFormInput, BButton, BRow, BCol, BForm, BOverlay, BFormGroup,
} from 'bootstrap-vue'
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import SuccessMessage from '@core/mixins/SuccessMessage'
import ErrorMessage from '@core/mixins/ErrorMessage'
import ImportExcel from '@core/components/excel/ImportExcel.vue'
import { RepositoryFactory } from '@/repository/RepositoryFactory'
import { tenant, bucketUrl, defaultStateExcelTemplate } from '@/constants/config'

const ResourceRepository = RepositoryFactory.get('resource')

export default {
  components: {
    BCard,
    ImportExcel,
    BTable,
    BFormInput,
    BButton,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BOverlay,
    vSelect,
  },
  directives: {
    Ripple,
  },
  mixins: [SuccessMessage, ErrorMessage, heightTransition],
  data() {
    return {
      tenant,
      bucketUrl,
      defaultStateExcelTemplate,
      items: [],
      nextTodoId: 2,
      tableData: [],
      header: [],
      sheetName: '',
      countryOptions: [],
      fields: [
        { key: 'state_name', label: 'State Name', editable: true },
      ],
      loading: false,
      selectedCountry: '',
    }
  },
  async mounted() {
    this.initTrHeight()
  },
  created() {
    window.addEventListener('resize', this.initTrHeight)
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
  methods: {
    async loadDataInTable({ results, header, meta }) {
      this.loading = true
      this.header = header
      this.tableData = results.map(v => {
        const obj = v
        return obj
      })
      this.readDataforCountry()
      this.sheetName = meta.sheetName
      this.loading = false
    },
    repeateAgain() {
      this.items.push({
        id: this.nextTodoId += this.nextTodoId,
      })

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight)
      })
    },
    async readDataforCountry() {
      try {
        const { data } = (await ResourceRepository.getCountryList()).data
        data.forEach(v => {
          if (!this.countryOptions.find(c => c.id === v.id)) {
            this.countryOptions.push(v)
          }
        })
      } catch (e) {
        this.convertAndNotifyError(e)
      }
    },
    async onClickSubmit() {
      this.loading = true
      const payload = {
        states: [],
      }
      this.tableData.forEach(n => {
        const obj = { ...n, country_id: this.selectedCountry } // Insert country_id
        payload.states.push(obj)
      })
      try {
        await ResourceRepository.createBulkStates(payload)
        this.showSuccessMessage('State created successfully')
        this.$router.push({ path: '/operation-settings/state' })
      } catch (error) {
        if (error.response && error.response.data && error.response.data.errors) {
          const states = error.response.data.errors

          // Iterate over each state and show its error message separately
          Object.keys(states).forEach(stateKey => {
            const stateErrors = states[stateKey]
            stateErrors.forEach(stateError => {
              const errorMessage = `${stateError}`
              this.showErrorMessage(errorMessage) // Set the flag if there are errors
            })
          })
        }
      }
      this.loading = false
    },
    removeItem(index) {
      this.items.splice(index, 1)
      this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },
    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form.scrollHeight)
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.repeater-form {
  overflow: hidden;
  transition: .35s height;
}
.textSmall {
    max-width: 1000px;
}
.excelUploadtable{
    text-align: center;
}
@media (min-width: 767px) {
  .excelUploadtable{
    padding-bottom: 50px !important;
    width: 800px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }
}
.custom-button-color {
  background-color: #4186f5 !important;
  border-color: #4186f5 !important;
}
</style>
<style lang="scss">
@import '@core/scss/vue/required.scss';
</style>
